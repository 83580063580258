<template>
  <div class="banner">
    <div class="title">
      <img src="https://mktv-in-cdn.mockuai.com/16354784549775855.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
@import "@/style/common";

.banner {
  .pxToVw(height, 380);
  width: 100vw;

  padding-top: 150px;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16347160810235773.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  & .title {
    margin: 0 auto;
    img {
      z-index: 22;
      display: block;
      margin: 0 auto;
      .pxToVw(width, 388);
      .pxToVw(height, 75);
      .pxToVw(margin-top, 153);
    }
  }
}
</style>
