<template>
  <div class="knowledge-container">
    <div class="main">
      <div class="news_left" v-if="knowledgeList.length > 0">
        <div
          class="news_lis"
          v-for="(item, index) in knowledgeList"
          :key="index"
        >
          <a :href="`article/${item.id}`" target="_blank">
            <div class="news_lis_left">
              <img :src="item.thumb_image" alt="" />
            </div>
            <div class="news_lis_right">
              <div class="title">{{ item.title.rendered }}</div>
              <div class="desc">{{ item.excerpt.rendered }}</div>
              <div class="label">
                <div class="type">
                  <div class="img"></div>
                  <span class="text">{{ item.category_name }}</span>
                </div>
                <div class="time">
                  <div class="img"></div>
                  <span class="text">{{ item.date | date }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="news_left" v-else>
        <div class="info_null">暂无该文章内容</div>
      </div>
      <div class="categr_right">
        <div class="knowledge_lis">
          <div class="title">
            <span>魔筷知识库</span>
          </div>
          <div class="lis">
            <div
              class="item"
              v-for="(item, index) in knowledge_list"
              :key="index"
              @mousemove="hoverIcon(index)"
              @mouseout="iconNone"
              @click="checkCategor(index + 1, item.id)"
            >
              <img :src="item.icon" alt="" v-if="hoverIdx !== index" />
              <img :src="item.hover" alt="" v-else />
              <span>{{ item.msg }}</span>
            </div>
          </div>
        </div>
        <div class="news_hot">
          <div class="title">
            <span>热门浏览</span>
          </div>

          <ul class="hot_main">
            <li class="hot_lis" v-for="(item, index) in hotList" :key="index">
              <a :href="item.link" target="_blank">
                <div class="left">
                  {{ index + 1 }}
                </div>
                <span class="right">{{ item.text }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="label_list">
          <div class="title">标签列表</div>
          <ul class="label_box">
            <li
              v-for="(item, index) in tags"
              :key="index"
              class="label_item"
              @click="getLabelTag(item.id, 'tags')"
            >
              <a href="javascript:;">{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :total="count"
        :page-size="10"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  time,
  news,
  ganhuo,
  ganhuoH,
  zhidao,
  zhidaoH,
  gushi,
  gushiH,
  dongtai,
  dongtaiH,
  kuaibao,
  kuaibaoH,
  jieda,
  jiedaH,
} from "@/static/base64";
import moment from "moment";
export default {
  needSerialize: true,
  data() {
    return {
      currentPage: 1,
      knowledgeList: [],
      count: 0,
      categories: "",
      time: time,
      news: news,
      hoverIdx: "",
      hotList: [],
      tags: [],
      labelTag: this.$route.params.id || "",
      labelTags: [],
      categories_id: "17,41,2,31,33,16",
      categories_count: 0,
      knowledge_list: [
        { icon: kuaibao, hover: kuaibaoH, msg: "一周筷报", id: 17 },
        { icon: dongtai, hover: dongtaiH, msg: "行业动态", id: 41 },
        { icon: gushi, hover: gushiH, msg: "网红故事", id: 2 },
        { icon: ganhuo, hover: ganhuoH, msg: "干货分享", id: 31 },
        { icon: zhidao, hover: zhidaoH, msg: "开店指导", id: 33 },
        { icon: jieda, hover: jiedaH, msg: "疑问解答", id: 16 },
      ],
    };
  },
  async created() {
    await this.getKnowledge();
    await this.getHotList();
    await this.getCount();
    await this.getTags();
  },

  methods: {
    async getKnowledge(id, type) {
      try {
        this.categories_count++;
        //    17   一周筷报、 41 行业动态、2  网红故事，31  干货分享，33 开店指导、16疑问解答
        this.labelTag = type == "tags" ? "" : this.labelTag;
        this.currentPage = type == "tags" ? 1 : this.currentPage;
        this.categories_id =
          this.$route.params.id && this.categories_count == 1
            ? ""
            : this.categories_id;
        const params = {
          per_page: 10,
          page: this.currentPage,
          categories: this.categories_id,
          tags: this.labelTag,
        };

        // console.log(params, "页面进来传的数据");

        const res = await axios.get(
          "https://www.mockuai.com/wp-json/wp/v2/posts?&orderby=date",
          { params }
        );
        this.knowledgeList = res.data;
        this.knowledgeList.forEach((v, i) => {
          this.knowledgeList[i].excerpt.rendered = this.knowledgeList[
            i
          ].excerpt.rendered
            .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, "\n")
            .replace(/<br\/?>/gi, "\n")
            .replace(/<[^>/]+>/g, "")
            .replace(/(\n)?<\/([^>]+)>/g, "")
            .replace(/\u00a0/g, " ")
            .replace(/&nbsp;/g, " ")
            .replace(/<\/?(img)[^>]*>/gi, "")
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&#39;/g, "'")
            .replace(/&quot;/g, '"')
            .replace(/<\/?.+?>/g, "")
            .replace(/&hellip;/g, "...");
        });
      } catch (error) {
        console.error(error || "获取新闻数据失败");
      }
    },
    async getCount(id = "17,41,2,31,33,16", type) {
      try {
        let url =
          type == "tags" || this.labelTag
            ? `https://wp.mockuai.com/wp-json/get/article/tags/count/${this.labelTag}`
            : `https://www.mockuai.com/wp-json/wp/v2/categories?include=${id}`;
        this.$nextTick(() => {
          console.log(url, "count请求的变化");
        });

        const res = await axios.get(url);

        if (res.data.length) {
          res.data.forEach((v) => {
            this.count += v.count;
          });
        } else {
          this.count = res.data.count;
        }
        this.$nextTick(() => {
          // console.log(this.count, "获取的count");
        });
      } catch (error) {
        console.error(error || "获取新闻数据失败");
      }
    },

    hoverIcon(index) {
      this.hoverIdx = index;
    },
    iconNone() {
      this.hoverIdx = "";
    },
    async getHotList() {
      try {
        const res = await axios.get(
          "https://www.mockuai.com/wp-json/wp/v2/posts?per_page=6&orderby=date&categories=17,41,2,31,33,16"
        );
        // console.log('新闻数据',res.data)
        this.hotList = res.data
          .filter((item) => item.title && item.title.rendered)
          .map((item) => ({
            text: item.title.rendered,
            link: `/article/${item.id}`,
          }));
      } catch (error) {
        console.error(error || "获取新闻数据失败");
      }
    },
    async getTags() {
      try {
        let params = {
          per_page: 50,
        };
        const res = await axios.get(
          "https://wp.mockuai.com/wp-json/wp/v2/tags",
          { params }
        );
        if (res.data) {
          this.tags = res.data;
        }
        // console.log(res, "获取的标签");
      } catch (error) {
        console.error("获取标签失败");
      }
    },
    onCurrentChange(page) {
      this.currentPage = page;
      this.getKnowledge();
    },
    checkCategor(index, id) {
      this.$emit("changeMoveMode", index, id);
    },
    getLabelTag(id, type) {
      // console.log("点击标签列表", id);
      this.labelTag = id;
      this.categories_id = "17,41,2,31,33,16";
      this.getKnowledge();
      this.getCount(id, type);
    },
  },
  filters: {
    date(value, format = "MM-DD") {
      let oldTime = new Date(value).getTime();
      let newTime = new Date().getTime();

      let deffDay = Math.ceil((newTime - oldTime) / 1000 / 60 / 60 / 24);
      if (deffDay <= 30) {
        return deffDay + "天前" + "  ( " + moment(value).format(format) + " )";
      }
      if (deffDay / 30 >= 12) {
        return (
          Math.floor(deffDay / 30 / 12) +
          "年前" +
          "  ( " +
          moment(value).format(format) +
          " )"
        );
      }
      if (deffDay > 30) {
        return (
          Math.ceil(deffDay / 30) +
          "月前" +
          "  ( " +
          moment(value).format(format) +
          " )"
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/style/common";

.knowledge-container {
  .pxToVw(margin-top, 60);
  .pxToVw(padding-left, 240);
  .pxToVw(padding-right, 240);

  .main {
    display: flex;
    .news_left {
      .pxToVw(width, 830);
      .info_null {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
    .news_lis {
      .pxToVw(width, 830);
      .pxToVw(height, 200);
      .pxToVw(margin-bottom, 61);
      a {
        display: flex;
        text-decoration: none;
      }
      .news_lis_left {
        .pxToVw(margin-right, 30);
        img {
          .pxToVw(width, 320);
          .pxToVw(height, 200);
          overflow: hidden;
          .pxToVw(border-radius, 16);
          display: block;
          object-fit: cover;
        }
      }
      .news_lis_right {
        .pxToVw(width, 480);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          .pxToVw(font-size, 24);
          .pxToVw(line-height, 33);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .title:hover {
          color: #2e6fed;
        }
        .desc {
          .pxToVw(font-size, 18);
          .pxToVw(line-height, 26);
          .pxToVw(margin-top, 10);
          .pxToVw(margin-bottom, 26);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .label {
          display: flex;
          .type,
          .time {
            display: flex;
            align-items: center;

            span {
              .pxToVw(font-size, 18);
              .pxToVw(line-height, 25);
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
          .time {
            .img {
              .pxToVw(width, 16);
              .pxToVw(height, 16);
              margin-right: 5px;
              background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAVtJREFUOE+tk7FLw1AQxr9LqJOI6NI4uAh2cPCf0MXBSSc3kZKz2MVZQnEWoVIvFHFz0snBRf8JQQcLXRxMBhEpTobk5NWmtMFGKr7hwb3H/d73vbsjZFaz2VxKkmQHwKqqzptrInoGcGtZ1lm5XH4cTKE08DxvolgsHgHYAHBKRNdxHLfNvW3bC6q6DmAXwFUYhvu1Wu2zCzebSXYc50ZV36Mo2q5Wq52sMhPX6/WpQqFwTkTTQRCsGUgXICInABxm3gSgabKItJh5MQMjEbkEEDDzHhnPcRzfRVFUyr4sIsrMfZspqKfkybbtFfJ9/1hV35j5MCt7FKCn+oCIZgzgAcCW67r34wB8318GcGH8dJIkmatUKh/jABqNxqRlWS+/AV4BzA7Vnajlum6pD8iz8FMp07O+hbxPzAOIyPcn5pVxFGCojHmNNAIw3Ej/0sop5M/DNCh13HH+AkCc6FfGZNtqAAAAAElFTkSuQmCC")
                no-repeat;
              background-size: 100% 100%;
            }
          }
          .type:hover .img {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAL9JREFUOE9jZGBgYNDLf3uYgYHBBsTGDxgf/Gf85Xx5gsQ9mDpGqAH/L00UBrPxAb38t//+MzA8Zv7/1+XCJLHbILWkGvCfkYkp6f+/f03///53vTxF5AbJBoBcqp//LuY/w7+eSxNFJFAM0Mt/e4uBgUEV7g1GhluXJgirw/joYQUyjCQXIIePXv5bcLhR1wAML0CCGcUbSN6hgQsIpQOCYYDVC4gkhx4jg9ELFIcBJQYQmZ2RrGBkPHxpgpAdAGPBnxG7uG4kAAAAAElFTkSuQmCC);
            background-size: 100% 100%;

            background-repeat: no-repeat;
            .pxToVw(width, 16);
            .pxToVw(height, 16);
            margin-right: 5px;
          }
          .type:hover .text {
            color: #2e6fed;
          }
          .type {
            .pxToVw(margin-right, 40);
            .img {
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAALhJREFUOE/Nk8ENwjAMRb8zBQsglkBcgC04caijjMGZAeJ5ugQqEkJijiRVKoLSCghVQcInR3Ke/b8TAgARqQEsY16Iq3NubYy5pDq6AwIzd/m7EBEP4BZC2Gitz7F2LCAopfbe+wOALTOfRgPipNbaHREdmXnWA4hIA2CeyWiYeZHOQ68ibNQEuT8i0vn2XcATCbFpT0Ym5wcTlN5B0YMXEtK94Ub+UcJkD6YAPv3Ojx5EVFdVtWoBb0WbEaMq1UMAAAAASUVORK5CYII=);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              .pxToVw(width, 16);
              .pxToVw(height, 16);
              margin-right: 5px;
            }
          }
        }
      }
    }

    .categr_right {
      .pxToVw(margin-left, 186);
      .knowledge_lis {
        box-sizing: border-box;
        .pxToVw(width, 400);
        .pxToVw(height, 286);
        .pxToVw(padding-left, 30);
        .pxToVw(padding-top, 35);
        .pxToVw(padding-bottom, 50);
        .pxToVw(padding-right, 102);
        .pxToVw(border-radius, 16);
        background: #ffffff;
        box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
        .title {
          .pxToVw(font-size, 24);
          .pxToVw(line-height, 33);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .lis {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item {
            .pxToVw(margin-top, 30);
            cursor: pointer;
            display: flex;
            align-items: center;
            img {
              .pxToVw(width, 16);
              .pxToVw(height, 17);
              .pxToVw(margin-right, 8);
            }
            span {
              .pxToVw(font-size, 18);
              .pxToVw(line-height, 25);
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
          .item:hover span {
            color: #2e6fed !important;
          }
        }
      }
      .news_hot {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 24px 1px;

        box-sizing: border-box;
        .pxToVw(width, 400);
        .pxToVw(height, 408);
        .pxToVw(border-radius, 11);
        .pxToVw(margin-top, 40);
        .pxToVw(padding-top, 35);
        .pxToVw(padding-left, 30);
        .pxToVw(padding-right, 30);
        .pxToVw(padding-bottom, 60);
        background: url(https://mktv-in-cdn.mockuai.com/16347881491189021.png)
          no-repeat;
        background-size: contain;
        .title {
          .pxToVw(font-size, 24);
          .pxToVw(line-heiht, 33);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff6600;
        }
        .hot_main {
          a {
            text-decoration: none;
            display: flex;
          }
          .hot_lis {
            .left {
              .pxToVw(width, 25);
              .pxToVw(height, 24);
              .pxToVw(border-radius, 4);
              .pxToVw(font-size, 18);
              .pxToVw(line-height, 24);
              .pxToVw(margin-right, 10);
              background: #dddddd;
              text-align: center;
              font-family: DIN-MediumItalic, DIN;
              font-weight: normal;
              color: #ffffff;
            }
          }
          .right {
            flex: 1;
            .pxToVw(font-size, 18) !important;
            .pxToVw(line-height, 22);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
        .hot_main :nth-child(2) {
          .left {
            background: #ee6a0c;
          }
        }
        .hot_main :nth-child(3) {
          .left {
            background: #f9ab0d;
          }
        }
        .hot_lis:nth-of-type(1) {
          .pxToVw(margin-top, 39);
          .left {
            background: red;
          }
        }
        .hot_lis {
          .pxToVw(margin-bottom, 25);
        }
        .hot_lis :hover .right {
          color: #2e6fed;
        }
      }
    }
    .label_list {
      box-sizing: border-box;
      .pxToVw(width, 400);
      .pxToVw(margin-top, 40);
      .pxToVw(padding-top, 35);
      .pxToVw(padding-left, 30);
      .pxToVw(padding-right, 30);
      .pxToVw(padding-bottom, 60);
      .pxToVw(border-radius, 16);
      background: #ffffff;
      box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
      .title {
        .pxToVw(font-size, 24);
        .pxToVw(line-height, 33);
        .pxToVw(margin-bottom, 26);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .label_box {
        display: flex;
        flex-wrap: wrap;
        .label_item {
          .pxToVw(margin-right, 10);
          .pxToVw(margin-bottom, 10);
          .pxToVw(width,100);
          text-align: center;
        }

        a {
          text-decoration: none;
          .pxToVw(font-size, 14);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          .pxToVw(width,100);

          display: block;
          .pxToVw(padding-top, 10);
          .pxToVw(padding-bottom, 10);
          // .pxToVw(padding-left, 20);
          // .pxToVw(padding-right, 20);
          .pxToVw(border-radius, 4);
          background: #f7f7f7;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .label_item :hover {
          color: #fff !important;
          background: #ff6600 !important;
        }
      }
    }
  }
  .pagination {
    text-align: center;
    .pxToVw(margin-top, 65);
    .pxToVw(padding-bottom, 80);
  }
  /deep/ .el-pagination.is-background .el-pager {
    li:hover {
      color: #ff5f23;
    }
    li.active {
      background: #ff6600;
      color: #fff;
    }
  }
  /deep/ button {
    .pxToVw(width,40);
    .pxToVw(height,40);
  }
  /deep/ .el-pager .number {
    .pxToVw(width,40);
    .pxToVw(height,40);
    .pxToVw(line-height,40);
    .pxToVw(font-size,18);
  }
  /deep/ .el-icon {
    .pxToVw(width,40);
    .pxToVw(height,40);
    .pxToVw(line-height,40);
  }
}
@media screen and (min-width: 1440px) {
  .pagination {
    /deep/ button {
      width: 40px;
      height: 40px;
    }
    /deep/ .el-pager .number {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
    }
    /deep/ .el-icon {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
