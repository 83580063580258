<template>
  <div class="knowledge-base">
    <shrinkable-header
      ref="header"
      :class="['knowledge-header', { t90: showHead ? '' : 't90' }]"
    >
      <p-header :fixed="false" type="flow"></p-header>

      <sub-header :title_box_show="true">
        <ul class="nav">
          <li
            :class="[
              'nav-item',
              { active: activeIdx === index ? 'active' : '' },
            ]"
            @click="changeMoveMode(index, item.id)"
            v-for="(item, index) in tabLis"
            :key="index"
          >
            <a href="javascript:;">{{ item.name }}</a>
          </li>
        </ul>
      </sub-header>
    </shrinkable-header>
    <section class="banner-section">
      <banner></banner>
    </section>
    <section class="knowledge-container">
      <knowledge-container
        ref="knowledge-container"
        @changeMoveMode="changeMoveMode"
      ></knowledge-container>
    </section>
  </div>
</template>

<script>
import Header from "@/layout/Header.vue";
import SubHeader from "@/components/SubHeader.vue";
import ShrinkableHeader from "@/components/ShrinkableHeader.vue";
import banner from "@/components/knowledge-base/Banner";
import KnowledgeContainer from "@/components/knowledge-base/KnowledgerContainer.vue";
export default {
  data() {
    return {
      activeIdx: "",
      showHead: true,
      categoriesId: "",
      tabLis: [
        { name: "首页", url: "", id: "17,41,2,31,33,16" },
        { name: "一周筷报", url: "", id: "17" },
        { name: "行业动态", url: "", id: "41" },
        { name: "网红故事", url: "", id: "2" },
        { name: "干货分享", url: "", id: "31" },
        { name: "开店指导", url: "", id: "33" },
        { name: "疑问解答", url: "", id: "16" },
      ],
    };
  },
  mounted() {
    this.onload();
  },
  methods: {
    onload() {
      window.addEventListener("scroll", () => {
        this.showHead =
          document.documentElement.scrollTop >= 150 ? false : true;
      });
    },
    changeMoveMode(index, id) {
      this.activeIdx = index;
      this.$refs["knowledge-container"].categories_id = id;
      this.$refs["knowledge-container"].currentPage = 1;
      this.$refs["knowledge-container"].labelTag = "";
      this.$refs["knowledge-container"].count = 0;
      this.$refs["knowledge-container"].getKnowledge();
      this.$refs["knowledge-container"].getCount(id);
    },
  },

  components: {
    "p-header": Header,
    SubHeader,
    ShrinkableHeader,
    banner,
    KnowledgeContainer,
  },
};
</script>

<style lang="less" scoped>
.knowledge-base {
  height: 100vw;

  .nav-item:hover a {
    color: #ff6600 !important;
  }

  .t90 {
    top: -90px !important;
    transition: all 0.5s !important;
  }
}
</style>
